<script setup lang="ts">
export interface IconChevronLeftProps {
  width?: number;
  height?: number;
  fill?: string;
}

const props = withDefaults(defineProps<IconChevronLeftProps>(), {
  width: 16,
  height: 16,
  fill: "currentColor",
});
</script>

<template>
  <svg :width="props.width" :height="props.height" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.756 20 15 17.897l-8.104-7.875L15 2.148 12.802 0 2.5 10.022z" :fill="props.fill" fill-rule="evenodd" />
  </svg>
</template>
